.search-form[data-v-6f86497b] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-6f86497b] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-6f86497b] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-6f86497b] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
[data-v-6f86497b] .el-button + .el-button {
  margin-left: 0px;
}
